
import PageInfo from '@/views/application-module/components/PageTemplate.vue';
import { defineComponent, reactive, ref, watch, onBeforeUnmount, toRefs } from 'vue';
import { computeStatusLabel, statusColor } from '@/views/service-management/business-service/utils/service-status-map';
import { getAppDetail, currentApp } from '../utils/dataCenter';
import AppEditDetail from '@/views/application-module/components/AppEditDetail.vue';
import {
  getApps,
  initApplyApp,
  startApp,
  stopApp,
  syncAppStatusByTSF,
  engineServicesApp,
  // enginePackageApp,
} from '@/api/app';
import { allService, getAllService } from '@/views/service-management/business-service/utils/service-data-utils';
import { ElMessage } from 'element-plus';
import IframeDiv from '../components/IframeDiv.vue';
import AppAuthList from './components/AppAuthList.vue';
import { getShowBool } from '@/utils/permission-show-module';
import ReleaseDialog from '../components/ReleaseDialog.vue';
import ServiceName from '@/views/service-management/components/ServiceName.vue';
import { getServiceShowName, getTemplateTIcon } from '../utils/utils';
import DomainConfig from '@/views/service-management/business-service/components/domainConfig/DomainConfig.vue';
import { openDomainDialog } from '@/views/service-management/business-service/components/domainConfig/dataCenter';
import LogDialog from '@/views/service-management/business-service/components/LogDialog.vue';
import { useRefrenceApp } from '../useRefrenceApp';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
import { removeEnvStorage } from '@/utils/env-storage';
import Version from '../components/upgrade-instance/Version.vue';
import useTenant from '@/views/tenant-management/useTenant';
export default defineComponent({
  name: 'ApplicationManDetail',
  components: {
    PageInfo,
    AppEditDetail,
    IframeDiv,
    ReleaseDialog,
    ServiceName,
    AppAuthList,
    DomainConfig,
    LogDialog,
    Version,
  },
  setup() {
    // 获取路由信息
    const { serviceId: appId, envId, env, envName } = useEnvAndServiceId() as any;
    onBeforeUnmount(() => {
      removeEnvStorage();
    });
    const appList = ref([]);
    const currentId = ref(appId);
    const releaseDialogRef = ref();
    // 组件初始化数据函数
    const init = () => {
      getAppDetail(String(appId));
      getApps({
        page: 1,
        pageSize: 10000,
        keyword: '',
      }).then((res) => {
        appList.value = res.data.rows.filter((row: any) => row.delState === 0);
      });
      currentId.value = appId;
    };

    const appLogRef: any = ref(null);

    init();
    // 初始化应用状态数据
    const statusData = reactive({
      color: '',
      label: '',
      statusText: '',
    });
    statusData.label = computeStatusLabel(0)['30'];
    statusData.color = statusColor['30'];
    const appName = ref('');
    const appDevType = ref(0 as number);

    // 按钮不可点击控制
    const disableData = reactive({
      initDisabled: true,
      startDisabled: true,
      stopDisabled: true,
    });
    const initDisableArr = [10, 20, 30];
    const startDisableArr = [0, 10, 12, 20, 30];
    const stopDisableArr = [0, 10, 11, 12, 20, 22, 30, 31];
    const doingMap: any = {
      10: '应用初始化/变更中...',
      20: '应用启动中...',
      30: '应用停止中...',
    };

    let syncTimer: any = null;

    const syncStatusTimer = () => {
      syncTimer = setInterval(() => {
        syncAppStatusByTSF([Number(currentApp.value.id)]);
      }, 5000);
    };

    const clearSyncTimer = () => {
      clearInterval(syncTimer);
      syncTimer = null;
    };

    // 监听轮询请求，持续变更数据
    watch(currentApp, (nn) => {
      // eslint-disable-next-line
      // @ts-ignore
      statusData.label = computeStatusLabel(nn.initTimes || 0)[nn.status];
      // eslint-disable-next-line
      // @ts-ignore
      statusData.color = statusColor[nn.status];
      statusData.statusText = doingMap[nn.status] || '';
      appName.value = currentApp.name;
      appDevType.value = currentApp.value.devType || 0;
      disableData.initDisabled = initDisableArr.includes(nn.status);
      disableData.startDisabled = startDisableArr.includes(nn.status);
      disableData.stopDisabled = stopDisableArr.includes(nn.status);

      // 停止中开启tsf推送轮询
      if (Number(nn.status) === 30) {
        if (!syncTimer) {
          syncStatusTimer();
        }
      } else {
        clearSyncTimer();
      }
    });

    const detailVisible = ref(false);
    // 开关基础信息弹框
    const toggle = () => {
      detailVisible.value = !detailVisible.value;
    };
    // 打开git仓库
    const openGit = () => {
      if (currentApp.value.sshHost && currentApp.value.deposit) {
        window.open(currentApp.value.sshHost + currentApp.value.deposit);
      } else {
        ElMessage.error('暂无仓库信息');
      }
    };
    // 按钮loading控制
    const initLoading = ref(false);
    const startLoading = ref(false);
    const stopLoading = ref(false);

    // 打开日志弹框
    const toggleLog = () => {
      appLogRef.value.handleOpenDialog();
    };

    const { tenant, fetchTenantInfo } = useTenant();
    if (!tenant) {
      fetchTenantInfo();
    }

    getAllService();

    const projectName = localStorage.getItem('projectName');
    const projectId = localStorage.getItem('projectId');

    // 初始化应用
    const initApp = async (initTimes: number, source: number) => {
      initLoading.value = true;
      currentApp.value.status = 10;
      disableData.initDisabled = true;
      try {
        if (appDevType.value === 1) {
          const isDepenedService = ref(true as boolean);
          const serviceIds = currentApp.value.dependencyServices.map((item: any) => {
            const service = allService.value.find((v: any) => v.name === item.dependencyServiceName);

            if (service) {
              return {
                id: `${tenant.value?.id}-${projectId}-${service.id}`,
                name: item.dependencyServiceName,
                version: item.dependencyServiceVersion,
              };
            }
            isDepenedService.value = false;
          });

          if (!isDepenedService.value) {
            ElMessage.error('关联服务不存在');
            return;
          }
          // 应用编排推送服务
          await engineServicesApp(`${projectId}-${appId}`, {
            openId: 'citybase001',
            appName: currentApp.value.name.split('.')[3],
            tenantName: tenant.value?.tenantEngAbbr,
            projectName,
            serviceIds,
          });

          // 应用编排拉取代码
          // const res = await enginePackageApp(`${projectId}-${appId}`, {
          //   openId: "citybase001",
          //   appVersion: "master",
          //   // zip: "sss",
          // });

          // console.log(res);
        }
        const { code } = await initApplyApp(currentApp.value.id, initTimes, source);
        if (code === 0) {
          ElMessage.success(initTimes > 0 ? '同步配置指令下发成功' : '初始化指令下发成功');
          getAppDetail(String(appId));

          toggleLog();
        }
        initLoading.value = false;
      } catch (e) {
        initLoading.value = false;
      }
      disableData.initDisabled = false;
    };

    const start = async () => {
      openDomainDialog();
    };

    // 停止
    const stop = async () => {
      stopLoading.value = true;
      currentApp.value.status = 30;
      disableData.stopDisabled = true;
      try {
        const { code } = await stopApp(currentApp.value.id);
        if (code === 200) {
          ElMessage.success('停止指令下发成功');
          getAppDetail(String(appId));
          toggleLog();
        }
        stopLoading.value = false;
      } catch (e) {
        stopLoading.value = false;
      }
      disableData.stopDisabled = false;
    };

    // 确定启动
    const handleConfirmStart = async (configData: any) => {
      startLoading.value = true;
      currentApp.value.status = 20;
      try {
        const { code } = await startApp({
          serviceId: currentApp.value.id,
          branch: 'master',
          ...configData,
        });
        if (code === 0) {
          ElMessage.success('启动指令下发成功');
          getAppDetail(String(appId));
          toggleLog();
        }
        startLoading.value = false;
      } catch (e) {
        startLoading.value = false;
      }
    };

    const timer = setInterval(() => {
      getAppDetail(String(appId));
    }, 5000);

    onBeforeUnmount(() => {
      clearInterval(timer);
      clearSyncTimer();
    });

    const changeApp = (e: any) => {
      let url = '';
      if (envId) {
        url = `/env/${env.toLocaleLowerCase()}/${envId}/micro-app/${e}?env=${env}&envName=${envName}`;
      } else {
        url = `/apps/micro/${e}`;
      }
      window.location.replace(url);
    };

    // 下侧组件名称
    const drawerName = ref('');
    // 是否显示底部抽屉
    const isShowDownDrawer = ref(false);

    watch(isShowDownDrawer, (nn) => {
      if (!nn) {
        drawerName.value = '';
      }
    });

    // 打开接口配置
    const openPropertyInfo = () => {
      if (drawerName.value === 'AppAuthList') {
        isShowDownDrawer.value = false;
        drawerName.value = '';
      } else {
        isShowDownDrawer.value = true;
        drawerName.value = 'AppAuthList';
      }
    };
    const handlePublishVersion = () => {
      releaseDialogRef.value.handleOpenDialog(currentApp.value);
    };

    // 打开应用编排
    const openEngine = () => {
      ElMessage.error('功能暂未实现!!');

      // if (currentApp.value.status > 9) {
      //   ElMessage.error("应用未初始化成功!!");
      // } else {
      //   window.open(
      //     `http://app-engine.gospursgo.test.citybase.tencent-cloud.com/#/${projectId}-${appId}/page/base?openId=citybase001`
      //   );
      // }
    };

    useRefrenceApp(currentApp);
    return {
      statusData,
      currentApp,
      detailVisible,
      toggle,
      currentId,
      appList,
      openGit,
      initApp,
      start,
      stop,
      appLogRef,
      toggleLog,
      changeApp,
      appName,
      ...toRefs(disableData),
      getShowBool,
      drawerName,
      isShowDownDrawer,
      openPropertyInfo,
      releaseDialogRef,
      handlePublishVersion,
      getServiceShowName,
      handleConfirmStart,
      env,
      getTemplateTIcon,
      openEngine,
    };
  },
});
