<template>
  <div class="drawer-content">
    <div class="drawer-content__main">
      <list-wrap :loading="loading" :inProject="false" :empty="dataList.length === 0" :hasCreateAuth="false">
        <el-table
          :data="dataList"
          style="width: 100%"
          height="calc(100% - 30px)"
          row-key="$id"
          class="apis-table"
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column prop="eName" label="英文名">
            <template #default="scope">
              <span @click="DateSee(scope.row)" style="color: #80aaf8; cursor: pointer">{{ scope.row.eName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="展示名">
            <template #default="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="mainApis" label="主接口">
            <template #default="scope">
              <span>{{ scope.row.mainApis }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="relatedApis" label="相关接口">
            <template #default="scope">
              <span>{{ scope.row.relatedApis }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="description" label="描述">
            <template #default="scope">
              <span>{{ scope.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="actions" label="操作" align="right" width="180">
            <template #default="scope">
              <div v-show="!isRefApp">
                <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button type="text" @click="handleRemove(scope.row)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <packaged-pagination
          v-if="dataList.length && !loading"
          :current-page="searchProps.page"
          :page-size="searchProps.pageSize"
          :total="total"
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
        ></packaged-pagination>
        <div class="error-wrap">{{ formError }}</div>
      </list-wrap>
    </div>
    <div class="drawer-content__btns">
      <el-button v-show="!isRefApp" @click="handleToEditStats" type="primary">新增</el-button>
      <el-button @click="handleClose">{{ isEditStats ? '取消' : '关闭' }}</el-button>
    </div>
  </div>
  <el-dialog :title="isEditabletitle" v-model="isVisable" width="500px" destroy-on-close>
    <el-form ref="detailInfoRef" :rules="rules" :model="detailInfo" label-width="140px" label-position="left">
      <el-form-item label="操作权限英文名" prop="eName">
        <el-input :disabled="flag" v-model="detailInfo.eName" placeholder="请输入操作权限英文名"></el-input>
      </el-form-item>
      <el-form-item
        label="操作权限展示名"
        prop="name"
        :rules="[
          {
            required: true,
            message: '操作权限展示名不能为空',
            trigger: 'blur',
          },
          { min: 1, max: 128, message: '操作权限展示名长度在1到128个字符之间' },
        ]"
      >
        <el-input :disabled="flag" v-model="detailInfo.name" placeholder="请输入操作权限展示名"></el-input>
      </el-form-item>
      <el-form-item label="主接口" prop="mainApis">
        <el-cascader
          filterable
          :disabled="flag"
          @change="mainApiChange"
          style="width: 100%"
          v-model="mainApis"
          :props="propsInfo"
          :options="ApisList"
        ></el-cascader>
      </el-form-item>
      <el-form-item prop="relatedApis" label="相关接口">
        <el-cascader
          filterable
          :disabled="flag"
          @change="relatedApiChange"
          style="width: 100%"
          v-model="relatedApis"
          :props="propsInfoApis"
          :options="ApisList"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="描述" prop="description" :rules="[{ max: 512, message: '描述不能操过512个字符' }]">
        <el-input :disabled="flag" type="textarea" v-model="detailInfo.description" placeholder="请输入描述"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button v-if="!flag" type="primary" @click="updateAppDetail('detailInfo')">确定</el-button>
      <el-button v-if="!flag" @click="isEditable">取消</el-button>
      <el-button v-if="flag" type="primary" @click="isVisable = false">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import {
  computed,
  defineComponent,
  ref,
  toRefs,
  getCurrentInstance,
  watch,
  onMounted,
  reactive,
  unref,
  inject,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import PackagedPagination from '@/components/pagination/Index.vue';
import { ElMessageBox } from 'element-plus';
import {
  getServiceApis,
  getAppidPerion,
  postAppidPerion,
  postAppsidPerion,
  postDeletePerion,
} from '@/api/servers/index';
import _ from 'lodash';
import { getShowBool } from '@/utils/permission-show-module';
import { alloverEdit } from '@/layout/messageCenter/routerRef';
import { REFRENCE_APP } from '../../useRefrenceApp';
import { useEnvAndServiceId } from '@/views/env/detail/detail';
export default defineComponent({
  name: 'AppAuthList',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  components: {
    PackagedPagination,
  },
  setup(props, { emit }) {
    const { serviceId: appId } = useEnvAndServiceId();
    const loading = ref(false);
    const isEditStats = ref(false);
    const isAdd = ref(false);
    const editId = ref('');
    const sourceList = ref([]);
    const dataList = ref([]);
    const inputRefs = ref({});
    const instance = getCurrentInstance();
    const formError = ref('');
    const hasCancelBtn = ref(true);
    const isEditabletitle = ref('新建操作权限');
    const isNew = ref(true);
    const isVisable = ref(false);
    const associatedList = ref([]);
    const state = reactive({
      detailInfoRef: null,
      flag: false,
      ApisList: [],
      isFirAdd: true,
      rules: {
        eName: [
          {
            required: true,
            message: '操作权限英文名不能为空',
            trigger: 'blur',
          },
          { min: 1, max: 128, message: '操作权限英文名长度在1到128个字符之间' },
          {
            validator(rule, value, callback) {
              //  校验英文的正则
              if (/[a-zA-z0-9\\-]$/.test(value) === false) {
                callback(new Error('操作权限英文名只能是英文、数字、中划线(-)'));
              } else {
                // 校验通过
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        description: [
          {
            validator(rule, value, callback) {
              if (value.length > 512) {
                return callback(new Error('描述长度不能大于512'));
              }
              callback();
            },
          },
        ],
      },
      detailInfo: {
        name: '',
        eName: '',
        description: '',
        mainApis: '',
        relatedApis: '',
        code: '',
      },
      total: 0,
      searchProps: {
        pageSize: 10,
        page: 1,
        keyword: '',
      },
      mainApis: [],
      relatedApis: [],
      propsInfo: {
        value: 'name',
        label: 'name',
      },
      propsInfoApis: {
        value: 'name',
        label: 'name',
        multiple: true,
      },
    });

    // 获取接口数据
    const getAuthData = async () => {
      const { data } = await getServiceApis({ appId });
      state.ApisList = data;
    };
    //  获取列表
    const getListDate = async () => {
      const { data } = await getAppidPerion(appId, {
        page: state.searchProps.page,
        pageSize: state.searchProps.pageSize,
        keyword: state.searchProps.keyword,
      });
      dataList.value = data.rows;
      state.total = data.count;
    };

    // 每页条数改变
    const handlePageSizeChange = (pageSize) => {
      state.searchProps.pageSize = pageSize;
      getListDate();
    };

    // 页数切换
    const handlePageChange = (page) => {
      state.searchProps.page = page;
      getListDate();
    };

    onMounted(() => {
      getAuthData();
      getListDate();
    });

    // 新增
    const handleToEditStats = () => {
      state.flag = false;
      state.isFirAdd = true;
      getAuthData();
      isNew.value = true;
      isEditabletitle.value = '新建操作权限';
      state.detailInfo.name = '';
      state.detailInfo.eName = '';
      state.detailInfo.description = '';
      state.detailInfo.mainApis = '';
      state.detailInfo.relatedApis = '';
      state.detailInfo.code = '';
      state.mainApis = [];
      state.relatedApis = [];
      isVisable.value = true;
    };

    // 新增、编辑确定
    const updateAppDetail = async () => {
      const form = unref(state.detailInfoRef);
      if (!form) return;
      try {
        await form.validate();
        // const uuid = uuidv4();
        // state.detailInfo.code = uuid;
        state.detailInfo.code = state.detailInfo.eName;
        const payload = {
          ...state.detailInfo,
          uniqueCode: uuidv4(),
        };
        if (isNew.value) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let index = 0; index < dataList.value.length; index++) {
            if (dataList.value[index].name === state.detailInfo.name) {
              instance.proxy.$message({
                type: 'warning',
                message: '操作权限展示名不能重复!',
              });
              return false;
            }
          }
        }
        if (state.isFirAdd) {
          const { code } = await postAppidPerion(appId, payload);
          if (code === 0) {
            instance.proxy.$message({
              type: 'success',
              message: '新增成功',
            });
            isVisable.value = false;
            state.mainApis = [];
            state.relatedApis = [];
            state.ApisList = [];
            getListDate();
          }
        } else {
          const { code } = await postAppsidPerion(state.detailInfo.id, {
            name: state.detailInfo.name,
            eName: state.detailInfo.eName,
            description: state.detailInfo.description,
            mainApis: state.detailInfo.mainApis,
            relatedApis: state.detailInfo.relatedApis,
            code: state.detailInfo.code,
          });
          if (code === 0) {
            instance.proxy.$message({
              type: 'success',
              message: '编辑成功',
            });
            isVisable.value = false;
            form.clearValidate();
            state.mainApis = [];
            state.relatedApis = [];
            state.ApisList = [];
            getListDate();
          }
        }
      } catch (error) {
        return false;
      }
    };

    // 删除
    const handleRemove = async (row) => {
      ElMessageBox.confirm(`确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code } = await postDeletePerion(row.id);
        if (code === 0) {
          instance.proxy.$message({
            type: 'success',
            message: '删除成功',
          });

          state.ApisList = [];
          getListDate();
        }
      });
    };

    const isEditable = () => {
      isVisable.value = false;
    };

    const relatedApiChange = (name) => {
      const arr = [];
      name.forEach((item) => {
        const itemFor = `${item[0]}/${item[1]}`;
        arr.push(itemFor);
      });
      state.detailInfo.relatedApis = arr.toString();
    };

    const mainApiChange = (name) => {
      state.detailInfo.mainApis = `${name[0]}/${name[1]}`;
    };

    const beforeEditOrAdd = async () => {
      if (editId.value || isAdd.value) {
        return ElMessageBox.confirm(`有未保存的更改，确定放弃更改吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
      }
      return Promise.resolve(true);
    };
    // 编辑
    const handleEdit = async (row) => {
      state.flag = false;
      state.mainApis = [];
      state.relatedApis = [];
      getAuthData();
      state.detailInfo = JSON.parse(JSON.stringify(row));
      state.isFirAdd = false;
      isEditabletitle.value = '编辑操作权限';
      isNew.value = false;
      const [serviceName, ...methodUrl] = row.mainApis?.split('/');
      state.mainApis.push(serviceName, methodUrl.join('/'));
      if (row.relatedApis) {
        row.relatedApis.split(',').forEach((item) => {
          const add = [];
          const [serviceName, ...methodUrl] = item?.split('/');
          add.push(serviceName, methodUrl.join('/'));
          state.relatedApis.push(add);
        });
      }
      isVisable.value = true;
    };

    const DateSee = async (row) => {
      isEditabletitle.value = '查看操作权限';
      state.flag = true;
      state.mainApis = [];
      state.relatedApis = [];
      getAuthData();
      state.detailInfo = JSON.parse(JSON.stringify(row));
      const [serviceName, ...methodUrl] = row.mainApis?.split('/');
      state.mainApis.push(serviceName, methodUrl.join('/'));
      if (row.relatedApis) {
        row.relatedApis.split(',').forEach((item) => {
          const add = [];
          const [serviceName, ...methodUrl] = item?.split('/');
          add.push(serviceName, methodUrl.join('/'));
          state.relatedApis.push(add);
        });
      }
      isVisable.value = true;
    };

    // 取消
    const handleCancel = () => {
      isAdd.value = false;
      editId.value = '';
      formError.value = '';
      dataList.value = _.cloneDeep(sourceList.value);
    };

    const getModelName = (modelId) => {
      const models = props.modelList || [];
      const model = models.find((item) => item.id === Number(modelId));
      return model ? model.name : '';
    };

    const handleClose = () => {
      if (isAdd.value || editId.value) {
        ElMessageBox.confirm(`编辑中的数据尚未保存，是否退出?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          // handleCancel();
          isEditStats.value = false;
          alloverEdit.value = false;
        });
      } else {
        // handleCancel();
        isEditStats.value = false;
        alloverEdit.value = false;
        emit('back');
      }
    };

    const showEditBtns = computed(() => getShowBool('apiUpdate') && isEditStats.value);

    watch(
      () => props.id,
      () => {
        console.log(props.id, 'this is id changed');
        fetchList();
      },
    );
    const isRefApp = inject(REFRENCE_APP);
    return {
      relatedApiChange,
      mainApiChange,
      editId,
      dataList,
      inputRefs,
      formError,
      loading,
      hasCancelBtn,
      isEditStats,
      handleEdit,
      handleCancel,
      getModelName,
      handleClose,
      getShowBool,
      handleToEditStats,
      showEditBtns,
      isEditabletitle,
      isVisable,
      ...toRefs(state),
      updateAppDetail,
      isEditable,
      associatedList,
      handlePageSizeChange,
      handlePageChange,
      beforeEditOrAdd,
      handleRemove,
      DateSee,
      isRefApp,
    };
  },
});
</script>
<style lang="scss" scoped>
.apis-table {
  :deep(.el-select),
  :deep(.el-input) {
    width: 100%;
  }

  :deep(.is-error .el-input__inner) {
    border-color: #f56c6c;
  }

  :deep(.cell) {
    line-height: 28px;
  }

  :deep(.system-row) {
    background: #f5f7fa;
  }
}

.error-wrap {
  color: #f56c6c;
  height: 20px;
  line-height: 20px;
  margin: 5px 10px;
}
</style>
